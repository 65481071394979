import { Fragment, useContext, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Close, ExpandMore, Send } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import ReactPlayer from 'react-player/lazy'
import ScrollContainer from 'react-indiana-drag-scroll'
import { transferBlackList } from '../settings'
import {
  attributesToShow,
  formatTokenID,
  isImageURL,
  shortenAddress,
} from '../utils/utils'
import { formatUnits, getAddress } from 'ethers/lib/utils'
import NFTMenu from './NFTMenu'
import { TOKEN_TYPE } from '../constants/contract'
import { DispatchContext, StateContext } from '../constants/contexts'
import { ACTION_TYPE } from '../constants/actionType'

const copyText = 'Copy Owner Address'
const copied = 'Copied!'

const NFTList = props => {
  const { walletAddress, previewAddress, expanded } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { nftList, onTransfer } = props

  const [copyOwnerText, setCopyOwnerText] = useState(copyText)

  const setExpanded = (nftAddressList, isExpanded) =>
    dispatch({
      type: ACTION_TYPE.setExpanded,
      payload: { nftAddressList, isExpanded },
    })

  const showTransferDialog = useCallback(
    ({ data, onTransfer, onClose }) =>
      dispatch({
        type: ACTION_TYPE.showTransferDialog,
        payload: { data, onTransfer, onClose },
      }),
    [dispatch]
  )
  const hideTransferDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideTransferDialog }),
    [dispatch]
  )

  const showImageViewer = useCallback(
    ({ images, onClose }) =>
      dispatch({
        type: ACTION_TYPE.showImageViewer,
        payload: { images, onClose },
      }),
    [dispatch]
  )
  const hideImageViewer = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideImageViewer }),
    [dispatch]
  )

  const onClickImage = (name, image) => () => {
    showImageViewer({
      images: [{ src: image, alt: name, downloadUrl: image }],
      onClose: () => hideImageViewer(),
    })
  }

  const handleExpanded = address => (event, isExpanded) => {
    setExpanded([address], isExpanded)
  }

  const handleClickOwner = ownerAddress => async () => {
    await navigator.clipboard.writeText(ownerAddress)
    setCopyOwnerText(copied)
  }
  const handleBlurOwner = () => {
    setCopyOwnerText(copyText)
  }

  const handleClickSend = (nft, tokenIndex) => () => {
    showTransferDialog({
      data: { nft, tokenIndex },
      onTransfer: async (nftAddress, tokenId, toAddress) => {
        await onTransfer(nftAddress, tokenId, toAddress)
        hideTransferDialog()
      },
      onClose: () => hideTransferDialog(),
    })
  }

  const getTitle = nft => {
    const { symbol, name } = nft
    if (symbol && name) return `${symbol} : ${name}`
    if (symbol) return symbol
    if (name) return name
  }

  const isLargeDevice = useMediaQuery(theme => theme.breakpoints.up('sm'))

  const renderMedia = token => {
    const url = token.animation_url || token.gif_url || token.image || ''

    if ((!isImageURL(url) && ReactPlayer.canPlay(url)) || token.animation_url) {
      return (
        <Box sx={{ height: '14em' }}>
          <ReactPlayer
            url={url}
            controls
            height='100%'
            width='100%'
            playing
            loop
            muted
          />
        </Box>
      )
    }

    return url ? (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component='img'
          sx={{ height: '14em' }}
          src={url}
          alt={token.name}
        />
      </Box>
    ) : null
  }

  const transferable = (nftAddress, type, token) => {
    try {
      if (transferBlackList.map(getAddress).includes(getAddress(nftAddress)))
        return false

      if (type === TOKEN_TYPE.ERC721) {
        return (
          !!walletAddress &&
          !!token?.owner &&
          getAddress(walletAddress) === getAddress(token.owner)
        )
      }
      if (type === TOKEN_TYPE.ERC1155) {
        return (
          !!walletAddress &&
          !!previewAddress &&
          token?.balance?.gt(0) &&
          getAddress(walletAddress) === getAddress(previewAddress)
        )
      }
      return false
    } catch (err) {
      return false
    }
  }

  return (
    <Fragment>
      {nftList.map(nft =>
        nft?.tokens?.length ? (
          <Accordion
            key={nft.address}
            expanded={!!expanded?.[getAddress(nft.address)]}
            onChange={handleExpanded(nft.address)}
          >
            <AccordionSummary expandIcon={<ExpandMore />} aria-label='Expand'>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: {
                    xs: '80vw',
                    sm: '85vw',
                    md: '90vw',
                  },
                }}
              >
                <Avatar
                  sx={{
                    color: theme => theme.palette.secondary.contrastText,
                    backgroundColor: theme => theme.palette.secondary.main,
                    marginRight: 2,
                  }}
                >
                  {nft.tokens?.length || 0}
                </Avatar>
                <Tooltip
                  title={`${
                    getTitle(nft)
                      ? `${getTitle(nft)}\n${nft.address}`
                      : nft.address
                  }`}
                  enterDelay={1200}
                  placement='top-start'
                >
                  <Typography
                    noWrap
                    sx={{
                      mr: 1,
                      width: 1,
                    }}
                  >
                    {getTitle(nft) || nft.address}
                  </Typography>
                </Tooltip>
                <Box
                  sx={{
                    borderRadius: '2em',
                    backgroundColor: { sm: '#393939' },
                  }}
                >
                  <NFTMenu
                    isSmallMode={!isLargeDevice}
                    isShowTransferMenu={false}
                    nft={nft}
                    onTransfer={onTransfer}
                    isShowRemoveMenu={true}
                  />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ScrollContainer vertical={false} hideScrollbars={false}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {nft.tokens.map((token, index) => {
                    const attributesTexts = attributesToShow(
                      token.attributes || token.properties
                    )
                    const tokenImage = token.gif_url || token.image || ''

                    return (
                      <Card
                        sx={{
                          overflow: 'initial',
                          margin: 1,
                          background: grey[700],
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        key={`${nft.address}-${token.id}`}
                      >
                        <CardActionArea
                          onClick={
                            tokenImage &&
                            (isImageURL(tokenImage) ||
                              !ReactPlayer.canPlay(tokenImage))
                              ? onClickImage(token.name, tokenImage)
                              : null
                          }
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            height: 1,
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Tooltip
                                title={token.id}
                                enterDelay={1200}
                                placement='top-start'
                              >
                                <Typography variant='h5' color='secondary'>
                                  {formatTokenID(token.id)}
                                </Typography>
                              </Tooltip>
                            }
                            title={
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Tooltip
                                  title={token.name}
                                  placement='top-start'
                                  enterDelay={1200}
                                >
                                  <Typography
                                    variant='h6'
                                    noWrap
                                    sx={{ maxWidth: '12rem' }}
                                  >
                                    {token.name}
                                  </Typography>
                                </Tooltip>
                                {!!nft.type &&
                                  nft.type !== TOKEN_TYPE.ERC721 &&
                                  token.balance.gt(0) && (
                                    <Box
                                      sx={{
                                        padding: 2,
                                        marginLeft: 2,
                                        background: theme =>
                                          theme.palette.primary.main,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '2em',
                                        minWidth: '3em',
                                        borderRadius: '1.5em',
                                      }}
                                    >
                                      <Close
                                        fontSize='small'
                                        sx={{
                                          color: theme =>
                                            theme.palette.primary.contrastText,
                                        }}
                                      />
                                      <Typography
                                        variant='h6'
                                        sx={{
                                          color: theme =>
                                            theme.palette.primary.contrastText,
                                        }}
                                      >
                                        {formatUnits(
                                          token.balance,
                                          token.decimals
                                        )}
                                      </Typography>
                                    </Box>
                                  )}
                              </Box>
                            }
                            sx={{ width: 1 }}
                          />
                          {renderMedia(token)}
                          {!!token.description || !!attributesTexts?.length ? (
                            <CardContent
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              {!!attributesTexts?.length &&
                                attributesTexts.map(attributesText => (
                                  <Typography
                                    key={attributesText}
                                    variant='body1'
                                    component='p'
                                    align='center'
                                    sx={{
                                      maxWidth: '20rem',
                                    }}
                                  >
                                    {attributesText}
                                  </Typography>
                                ))}
                              {!!attributesTexts?.length &&
                                !!token.description && (
                                  <Box sx={{ height: 16 }} />
                                )}
                              {!!token.description && (
                                <Typography
                                  variant='body2'
                                  color='textSecondary'
                                  component='p'
                                  align='center'
                                  sx={{
                                    maxWidth: '20rem',
                                  }}
                                >
                                  {token.description}
                                </Typography>
                              )}
                            </CardContent>
                          ) : (
                            <Box sx={{ height: 16 }} />
                          )}
                        </CardActionArea>
                        {(token.owner ||
                          transferable(nft.address, nft.type, token)) && (
                          <CardActions
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'flex-end',
                              width: 1,
                              px: 2,
                            }}
                          >
                            <Tooltip
                              title={copyOwnerText}
                              arrow
                              placement='bottom'
                              onClose={handleBlurOwner}
                              enterTouchDelay={0}
                              TransitionProps={{ timeout: 0 }}
                            >
                              <Typography
                                variant='body2'
                                color='secondary'
                                onClick={handleClickOwner(token.owner)}
                                noWrap
                                sx={{
                                  maxWidth: '6rem',
                                  flexShrink: 1,
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: theme => theme.palette.primary.main,
                                  },
                                }}
                              >
                                {shortenAddress(token.owner)}
                              </Typography>
                            </Tooltip>
                            {transferable(nft.address, nft.type, token) && (
                              <Button
                                variant='outlined'
                                color='primary'
                                endIcon={<Send />}
                                onClick={handleClickSend(nft, index)}
                              >
                                {'Transfer'}
                              </Button>
                            )}
                          </CardActions>
                        )}
                      </Card>
                    )
                  })}
                </Box>
              </ScrollContainer>
            </AccordionDetails>
          </Accordion>
        ) : null
      )}
    </Fragment>
  )
}

export default NFTList
