import { AccountBalanceWallet } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { getAddress } from 'ethers/lib/utils'
import { useCallback, useContext, useEffect } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'
import { chainLogo } from '../settings'
import { connectWallet, getWallet } from '../utils/ethUtils'
import { shortenAddress } from '../utils/utils'

const ConnectWallet = () => {
  const { walletAddress, chainId } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const setChainId = useCallback(
    chainId =>
      dispatch({
        type: ACTION_TYPE.setChainId,
        payload: { chainId },
      }),
    [dispatch]
  )
  const setWalletAddress = useCallback(
    walletAddress =>
      dispatch({
        type: ACTION_TYPE.setWalletAddress,
        payload: { walletAddress },
      }),
    [dispatch]
  )

  const setWallet = useCallback(async () => {
    try {
      const { walletAddress, chainId } = await getWallet()
      console.log({ walletAddress, chainId })
      setWalletAddress(getAddress(walletAddress))
      setChainId(chainId)
    } catch (err) {
      setWalletAddress('')
      console.error(err)
    }
  }, [setWalletAddress, setChainId])

  useEffect(() => {
    setWallet()
    window.ethereum?.on('accountsChanged', setWallet)

    return () => window.ethereum?.removeListener('accountsChanged', setWallet)
  }, [setWallet])

  useEffect(() => {
    const onChainChanged = chainId => {
      window.location.reload()
    }
    window.ethereum?.on('chainChanged', onChainChanged)
    return () => window.ethereum?.removeListener('chainChanged', onChainChanged)
  }, [])

  const handleClick = async e => {
    if (walletAddress) return

    await connectWallet()
  }

  return !walletAddress ? (
    <Button
      variant='outlined'
      sx={{
        ml: 2,
        height: 40,
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {chainLogo[chainId] ? (
          <Box
            component='img'
            src={chainLogo[chainId]}
            alt={chainId}
            sx={{
              height: '24px',
              borderRadius: '12px',
              mr: 1,
            }}
          />
        ) : (
          <AccountBalanceWallet sx={{ mr: 1 }} />
        )}
        <Typography>{'Connect Wallet'}</Typography>
      </Box>
    </Button>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 15px',
        border: '1px solid rgba(255,255,255,0.23)',
        borderRadius: '4px',
        height: 40,
        ml: 2,
      }}
    >
      {chainLogo[chainId] ? (
        <Box
          component='img'
          src={chainLogo[chainId]}
          alt={chainId}
          sx={{
            height: '24px',
            borderRadius: '12px',
            mr: 1,
          }}
        />
      ) : (
        <AccountBalanceWallet sx={{ mr: 1 }} />
      )}
      <Typography variant='subtitle1' noWrap>
        {shortenAddress(walletAddress)}
      </Typography>
    </Box>
  )
}

export default ConnectWallet
