import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material'
import { green, lightBlue } from '@mui/material/colors'
import { useCombinedReducer } from './utils/hook'
import { useReducer } from 'react'
import {
  nftAddressReducerArgs,
  expandedReducerArgs,
  viewModeReducerArgs,
  walletAddressReducerArgs,
  fetchingPercentsReducerArgs,
  loadingReducerArgs,
  addNFTDialogReducerArgs,
  hideNFTDialogReducerArgs,
  transferDialogReducerArgs,
  imageViewerReducerArgs,
  nftOrderReducerArgs,
  chainIdReducerArgs,
  previewAddressReducerArgs,
  removeNFTDialogReducerArgs,
} from './reducersArgs'
import Body from './components/Body'
import { DispatchContext, StateContext } from './constants/contexts'
import Fetching from './components/Fetching'
import Loading from './components/Loading'
import AddNFTDialog from './components/AddNFTDialog'
import HideNFTDialog from './components/HideNFTDialog'
import RemoveNFTDialog from './components/RemoveNFTDialog'
import TransferDialog from './components/TransferDialog'
import ImageViewer from './components/ImageViewer'
import LocalStorage from './components/LocalStorage'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: lightBlue,
    secondary: green,
    background: {
      default: '#303030',
      paper: '#393939',
    },
  },
})

const App = () => {
  const [state, dispatch] = useCombinedReducer({
    nftAddress: useReducer(...nftAddressReducerArgs),
    nftOrder: useReducer(...nftOrderReducerArgs),
    expanded: useReducer(...expandedReducerArgs),
    viewMode: useReducer(...viewModeReducerArgs),
    walletAddress: useReducer(...walletAddressReducerArgs),
    previewAddress: useReducer(...previewAddressReducerArgs),
    chainId: useReducer(...chainIdReducerArgs),
    fetchingPercents: useReducer(...fetchingPercentsReducerArgs),
    loading: useReducer(...loadingReducerArgs),
    addNFTDialog: useReducer(...addNFTDialogReducerArgs),
    hideNFTDialog: useReducer(...hideNFTDialogReducerArgs),
    removeNFTDialog: useReducer(...removeNFTDialogReducerArgs),
    transferDialog: useReducer(...transferDialogReducerArgs),
    imageViewer: useReducer(...imageViewerReducerArgs),
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DispatchContext.Provider value={dispatch}>
          <StateContext.Provider value={state}>
            <LocalStorage />
            <Fetching />
            <Loading />
            <Body />
            <AddNFTDialog />
            <HideNFTDialog />
            <RemoveNFTDialog />
            <TransferDialog />
            <ImageViewer />
          </StateContext.Provider>
        </DispatchContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
