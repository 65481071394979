import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useRef } from 'react'
import { useState } from 'react'
import { StateContext } from '../constants/contexts'
import { isSupportedNFT, isSupportEnumerable } from '../utils/ethUtils'
import {
  formatAddressField,
  formatTokenIDsField,
  isValidAddress,
  splitTokenIds,
} from '../utils/utils'

const AddNFTDialog = () => {
  const { addNFTDialog } = useContext(StateContext)
  const { isShow, onAdd, onClose } = addNFTDialog

  const [nftAddress, setNFTAddress] = useState('')
  const [addressErrorText, setAddressErrorText] = useState('')
  const [tokenIds, setTokenIds] = useState('')
  const [idsErrorText, setIdsErrorText] = useState('')

  const addressFieldRef = useRef(null)

  useEffect(() => {
    setNFTAddress('')
    setAddressErrorText('')
    setTokenIds('')
    setIdsErrorText('')
  }, [isShow])

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        addressFieldRef?.current?.focus()
      }, 300)
    }
  }, [isShow])

  const handleChangeNFTAddress = e => {
    const text = e.target.value
    setNFTAddress(formatAddressField(text))
    setAddressErrorText('')
  }

  const handleChangeTokenIds = e => {
    const text = e.target.value
    setTokenIds(formatTokenIDsField(text))
    setIdsErrorText('')
  }

  const onClickAdd = async () => {
    if (!nftAddress) {
      setAddressErrorText('Address is required.')
      return
    }
    if (!isValidAddress(nftAddress)) {
      setAddressErrorText('Incorrect address format.')
      return
    }
    if (!(await isSupportedNFT(nftAddress))) {
      setAddressErrorText('Not support this token.')
      return
    }

    const tokenIdList = splitTokenIds(tokenIds)

    if (!(await isSupportEnumerable(nftAddress))) {
      if (!tokenIdList?.length) {
        setIdsErrorText('Token IDs are required.')
        return
      }
    }

    try {
      await onAdd(nftAddress, tokenIdList)
    } catch (err) {
      console.error(err)
      setAddressErrorText(err.data?.message || err.message)
    }
  }

  const onBlurTokenIds = () => {
    setTokenIds(tokenIds.replace(/[-,]$/, ''))
  }

  return (
    <Dialog
      open={isShow}
      onClose={onClose}
      aria-labelledby='add-nft-dialog-title'
      aria-describedby='add-nft-dialog-description'
      transitionDuration={{ enter: 300, exit: 0 }}
    >
      <DialogTitle id='add-nft-dialog-title'>{'Add NFT?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='add-nft-dialog-description'>
          {'NFT address to show in the list.'}
        </DialogContentText>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '20em',
            mb: 2,
          }}
        >
          <TextField
            inputRef={addressFieldRef}
            sx={{ mt: 2 }}
            fullWidth
            label='NFT Address'
            placeholder='Address'
            variant='outlined'
            value={nftAddress}
            onChange={handleChangeNFTAddress}
            error={!!addressErrorText}
            helperText={addressErrorText}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            label='Token IDs (e.g. 0-3,5,7-9)'
            placeholder='IDs'
            variant='outlined'
            value={tokenIds}
            onChange={handleChangeTokenIds}
            onBlur={onBlurTokenIds}
            error={!!idsErrorText}
            helperText={idsErrorText}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={onClickAdd}
          color='primary'
          disabled={!!addressErrorText || !!idsErrorText}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddNFTDialog
