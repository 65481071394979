import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  Add,
  PhotoLibrary,
  Settings,
  UnfoldLess,
  UnfoldMore,
  ViewComfy,
  ViewList,
  Visibility,
} from '@mui/icons-material'
import { Fragment, useContext, useState } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'
import { VIEW_MODE } from '../constants/view'
import ConnectWallet from './ConnectWallet'

const NFTGalleryAppBar = () => {
  const { nftAddress, walletAddress, viewMode } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [settingsEl, setSettingsEl] = useState(null)

  const addNFT = (nftAddress, idList) =>
    dispatch({ type: ACTION_TYPE.addNFT, payload: { nftAddress, idList } })

  const setViewMode = viewMode =>
    dispatch({ type: ACTION_TYPE.changeViewMode, payload: { viewMode } })

  const setExpanded = (nftAddressList, isExpanded) =>
    dispatch({
      type: ACTION_TYPE.setExpanded,
      payload: { nftAddressList, isExpanded },
    })

  const unhideAllNFT = () =>
    dispatch({
      type: ACTION_TYPE.unhideAllNFT,
    })

  const hideAddNFTDialog = () =>
    dispatch({ type: ACTION_TYPE.hideAddNFTDialog })

  const showAddNFTDialog = ({ onAdd, onClose }) =>
    dispatch({
      type: ACTION_TYPE.showAddNFTDialog,
      payload: { onAdd, onClose },
    })

  const handleClickAddNFT = () => {
    const onAdd = (nftAddress, tokenIdList) => {
      addNFT(nftAddress, tokenIdList)
      hideAddNFTDialog()
    }

    const onClose = () => {
      hideAddNFTDialog()
    }

    showAddNFTDialog({ onAdd, onClose })
  }

  const handleExpandAll = () => {
    setExpanded(Object.keys(nftAddress), true)
    setSettingsEl(null)
  }

  const handleCollapseAll = () => {
    setExpanded(Object.keys(nftAddress), false)
    setSettingsEl(null)
  }

  const isLargeDevice = useMediaQuery(theme => theme.breakpoints.up('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 1,
        padding: 1,
      }}
    >
      {isLargeDevice && (
        <Fragment>
          <PhotoLibrary sx={{ mx: 1, fontSize: 40 }} />
          <Typography noWrap variant='h4'>
            {'NFT Gallery'}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Fragment>
      )}
      {isLargeDevice && (
        <Box
          sx={{
            backgroundColor: grey[900],
            borderRadius: '2em',
            display: 'flex',
            alignItems: 'center',
            mr: 2,
          }}
        >
          <Tooltip title={'Expand all'} arrow placement='bottom'>
            <span>
              <IconButton
                onClick={handleExpandAll}
                disabled={viewMode === VIEW_MODE.CARD}
                size='large'
              >
                <UnfoldMore />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={'Collapse all'} arrow placement='bottom'>
            <span>
              <IconButton
                onClick={handleCollapseAll}
                disabled={viewMode === VIEW_MODE.CARD}
                size='large'
              >
                <UnfoldLess />
              </IconButton>
            </span>
          </Tooltip>
          {viewMode === VIEW_MODE.CARD ? (
            <Tooltip title={'List view'} arrow placement='bottom'>
              <IconButton
                onClick={() => setViewMode(VIEW_MODE.LIST)}
                size='large'
              >
                <ViewList />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={'Card view'} arrow placement='bottom'>
              <IconButton
                onClick={() => setViewMode(VIEW_MODE.CARD)}
                size='large'
              >
                <ViewComfy />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={'Unhide all'} arrow placement='bottom'>
            <IconButton onClick={unhideAllNFT} size='large'>
              <Visibility />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {!!walletAddress && (
        <Button
          variant='outlined'
          startIcon={<Add />}
          onClick={handleClickAddNFT}
          sx={{ height: 40 }}
        >
          <Typography noWrap>{'Add NFT'}</Typography>
        </Button>
      )}
      {!isLargeDevice && <Box sx={{ flexGrow: 1 }} />}
      <ConnectWallet />
      {!!walletAddress && !isLargeDevice && (
        <Box>
          <Tooltip title={'Settings'} arrow placement='bottom'>
            <IconButton
              aria-controls='settings-menu'
              aria-haspopup='true'
              onClick={e => setSettingsEl(e.currentTarget)}
              edge='end'
              size='large'
            >
              <Settings />
            </IconButton>
          </Tooltip>
          <Menu
            id='settings-menu'
            keepMounted
            anchorEl={settingsEl}
            open={!!settingsEl}
            onClose={() => setSettingsEl(null)}
          >
            {viewMode === VIEW_MODE.CARD ? (
              <MenuItem
                onClick={() => {
                  setViewMode(VIEW_MODE.LIST)
                  setSettingsEl(null)
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 1,
                  }}
                >
                  <ViewList sx={{ color: grey[500] }} />
                </Box>
                <Typography noWrap>{'List view'}</Typography>
              </MenuItem>
            ) : (
              [
                <MenuItem
                  key={'CardViewMenu'}
                  onClick={() => {
                    setViewMode(VIEW_MODE.CARD)
                    setSettingsEl(null)
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 1,
                    }}
                  >
                    <ViewComfy sx={{ color: grey[500] }} />
                  </Box>
                  <Typography noWrap>{'Card view'}</Typography>
                </MenuItem>,
                <MenuItem
                  key={'ExpandAllMenu'}
                  onClick={handleExpandAll}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 1,
                    }}
                  >
                    <UnfoldMore sx={{ color: grey[500] }} />
                  </Box>
                  <Typography noWrap>{'Expand all'}</Typography>
                </MenuItem>,
                <MenuItem
                  key={'CollapseAllMenu'}
                  onClick={handleCollapseAll}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 1,
                    }}
                  >
                    <UnfoldLess sx={{ color: grey[500] }} />
                  </Box>

                  <Typography noWrap>{'Collapse all'}</Typography>
                </MenuItem>,
              ]
            )}
            <MenuItem
              key={'UnhideAllMenu'}
              onClick={() => {
                unhideAllNFT()
                setSettingsEl(null)
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 1,
                }}
              >
                <Visibility sx={{ color: grey[500] }} />
              </Box>

              <Typography noWrap>{'Unhide all'}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  )
}

export default NFTGalleryAppBar
