import Viewer from 'react-viewer'
import { useContext } from 'react'
import { StateContext } from '../constants/contexts'

const ImageViewer = () => {
  const { imageViewer } = useContext(StateContext)
  const { isShow, images, onClose } = imageViewer

  return (
    <Viewer
      visible={isShow}
      onClose={onClose}
      onMaskClick={onClose}
      images={images}
      changeable={false}
      downloadable
      downloadInNewWindow
      noNavbar
      noClose
    />
  )
}

export default ImageViewer
