import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { Clear, AccountBalanceWallet } from '@mui/icons-material'
import { getAddress } from 'ethers/lib/utils'
import { useContext, useEffect, useRef, useState } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'

const PreviewTextField = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { walletAddress, previewAddress } = state

  const setPreviewAddress = previewAddress =>
    dispatch({
      type: ACTION_TYPE.setPreviewAddress,
      payload: { previewAddress },
    })

  const [inputText, setInputText] = useState('')
  const [errorText, setErrorText] = useState('')

  const inputRef = useRef()

  useEffect(() => {
    setInputText(previewAddress)
    setErrorText('')
  }, [previewAddress])

  const handleChange = e => {
    const input = e.target.value
    setInputText(input.trim())
    setErrorText('')
  }

  const submitInput = () => {
    if (!inputText) {
      setPreviewAddress('')
      return
    }

    try {
      const addr = getAddress(inputText)
      setInputText(addr)
      setPreviewAddress(addr)
    } catch (e) {
      setErrorText('Incorrect address format.')
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      inputRef?.current?.blur()
    }
  }

  const handleClickClearInput = () => {
    inputRef?.current?.blur()
    setInputText('')
    setErrorText('')

    setPreviewAddress('')
  }

  const handleClickRestore = () => {
    inputRef?.current?.blur()
    setInputText(walletAddress)
    setErrorText('')

    setPreviewAddress(walletAddress)
  }

  const handleMouseDown = e => {
    e.preventDefault()
  }

  return (
    <Box sx={{ p: 1 }}>
      <TextField
        inputRef={inputRef}
        fullWidth
        label='Owner address (Leave blank to show all)'
        variant='outlined'
        size='small'
        value={inputText}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={submitInput}
        error={!!errorText}
        helperText={errorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Tooltip title={'Clear'} arrow placement='bottom'>
                <IconButton
                  aria-label='clear owner address'
                  onClick={handleClickClearInput}
                  onMouseDown={handleMouseDown}
                  size='large'
                >
                  <Clear />
                </IconButton>
              </Tooltip>
              <Tooltip title={'My address'} arrow placement='bottom'>
                <IconButton
                  aria-label='show my address'
                  onClick={handleClickRestore}
                  onMouseDown={handleMouseDown}
                  edge='end'
                  size='large'
                >
                  <AccountBalanceWallet />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default PreviewTextField
