import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.setChainId: {
      const { chainId } = action.payload
      return Number(chainId)
    }
    case ACTION_TYPE.clearChainId: {
      return 0
    }
    default: {
      return state
    }
  }
}

const initialState = 0

const initialize = (initialState = 0) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
