export const INTERFACE_ID = {
  ERC721: '0x80ac58cd',
  ERC721TokenReceiver: '0x150b7a02',
  ERC721Metadata: '0x5b5e139f',
  ERC721Enumerable: '0x780e9d63',
  ERC1155: '0xd9b67a26',
  ERC1155TokenReceiver: '0x4e2312e0',
  ERC1155Metadata_URI: '0x0e89341c',
  KAP721: '0xf422ea4e',
  KAP721Enumerable: '0x780e9d63',
  KAP721Metadata: '0x5b5e139f',
  KAP721MetadataOld: '0xf92ab93a',
}

export const TOKEN_TYPE = {
  ERC721: 'ERC721',
  ERC1155: 'ERC1155',
}
