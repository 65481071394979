import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  Delete,
  FileCopy,
  MoreVert,
  OpenInNew,
  Send,
  VisibilityOff,
} from '@mui/icons-material'
import { Fragment, useEffect, useState, useContext, useCallback } from 'react'
import { DispatchContext, StateContext } from '../constants/contexts'
import { ACTION_TYPE } from '../constants/actionType'
import {
  chainExplorerURL,
  chainIdWhitelist,
  transferBlackList,
} from '../settings'
import { getAddress } from 'ethers/lib/utils'

const NFTMenu = props => {
  const { previewAddress, chainId } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [isCopied, setIsCopied] = useState(false)
  const [optionsEl, setOptionsEl] = useState(null)

  const showTransferDialog = useCallback(
    ({ data, onTransfer, onClose }) =>
      dispatch({
        type: ACTION_TYPE.showTransferDialog,
        payload: { data, onTransfer, onClose },
      }),
    [dispatch]
  )
  const hideTransferDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideTransferDialog }),
    [dispatch]
  )

  const showHideNFTDialog = useCallback(
    ({ data, onHide, onClose }) =>
      dispatch({
        type: ACTION_TYPE.showHideNFTDialog,
        payload: { data, onHide, onClose },
      }),
    [dispatch]
  )
  const hideHideNFTDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideHideNFTDialog }),
    [dispatch]
  )

  const showRemoveNFTDialog = useCallback(
    ({ data, onRemove, onClose }) =>
      dispatch({
        type: ACTION_TYPE.showRemoveNFTDialog,
        payload: { data, onRemove, onClose },
      }),
    [dispatch]
  )
  const hideRemoveNFTDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideRemoveNFTDialog }),
    [dispatch]
  )

  const hideNFT = (nftAddress, id) =>
    dispatch({ type: ACTION_TYPE.hideNFT, payload: { nftAddress, id } })

  const removeNFT = (nftAddress, id) =>
    dispatch({ type: ACTION_TYPE.deleteNFT, payload: { nftAddress, id } })

  const { isSmallMode, isShowTransferMenu, isShowRemoveMenu, nft, onTransfer } =
    props

  useEffect(() => {
    setIsCopied(false)
    setOptionsEl(null)
  }, [previewAddress])

  const handleClickCopy = async e => {
    e.stopPropagation()
    const { address } = nft
    try {
      await navigator.clipboard.writeText(address)
      setIsCopied(true)
    } catch (err) {
      console.error(err)
    }
  }

  const handleClickNFTLink = e => {
    e.stopPropagation()

    const { address, type } = nft

    const url = chainExplorerURL[chainId]?.(address, type, previewAddress)

    if (url) {
      window.open(url)
    }
  }

  const handleClickOptions = e => {
    e.stopPropagation()
    setOptionsEl(e.currentTarget)
  }

  const handleCloseOptionsMenu = e => {
    e.stopPropagation()
    setOptionsEl(null)
  }

  const handleClickMenuItem = func => e => {
    func(e)
    setOptionsEl(null)
  }

  const handleClickSend = () => {
    showTransferDialog({
      data: { nft },
      onTransfer: async (nftAddress, tokenId, toAddress) => {
        await onTransfer(nftAddress, tokenId, toAddress)
        hideTransferDialog()
      },
      onClose: () => hideTransferDialog(),
    })
  }

  const handleClickHideNFT = e => {
    e.stopPropagation()
    showHideNFTDialog({
      data: { nft },
      onHide: (...args) => {
        hideNFT(...args)
        hideHideNFTDialog()
      },
      onClose: hideHideNFTDialog,
    })
  }

  const handleClickRemoveNFT = e => {
    e.stopPropagation()
    showRemoveNFTDialog({
      data: { nft },
      onRemove: (...args) => {
        removeNFT(...args)
        hideRemoveNFTDialog()
      },
      onClose: hideRemoveNFTDialog,
    })
  }

  const stopPropagation = e => e.stopPropagation()

  const shouldShowTransferMenu =
    isShowTransferMenu &&
    !transferBlackList.map(getAddress).includes(getAddress(nft.address))

  const renderSmallMode = () => (
    <Fragment>
      <Tooltip title={'Options'} arrow placement='top'>
        <IconButton
          onClick={handleClickOptions}
          onFocus={stopPropagation}
          aria-label='More options'
          aria-controls='options-menu'
          aria-haspopup='true'
          edge='end'
          size='large'
        >
          <MoreVert fontSize='small' sx={{ color: grey[500] }} />
        </IconButton>
      </Tooltip>
      <Menu
        id='options-menu'
        keepMounted
        anchorEl={optionsEl}
        open={!!optionsEl}
        onClose={handleCloseOptionsMenu}
      >
        {shouldShowTransferMenu && (
          <MenuItem
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={handleClickMenuItem(handleClickSend)}
          >
            <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center' }}>
              <Send fontSize='small' sx={{ color: grey[500] }} />
            </Box>
            <Typography variant='inherit' noWrap>
              {'Transfer'}
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={handleClickMenuItem(handleClickCopy)}
        >
          <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center' }}>
            <FileCopy fontSize='small' sx={{ color: grey[500] }} />
          </Box>
          <Typography variant='inherit' noWrap>
            {'Copy address'}
          </Typography>
        </MenuItem>
        {chainIdWhitelist.includes(chainId) && (
          <MenuItem
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={handleClickMenuItem(handleClickNFTLink)}
          >
            <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center' }}>
              <OpenInNew fontSize='small' sx={{ color: grey[500] }} />
            </Box>
            <Typography variant='inherit' noWrap>
              {'Open in Explorer'}
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={handleClickMenuItem(handleClickHideNFT)}
        >
          <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center' }}>
            <VisibilityOff fontSize='small' sx={{ color: grey[500] }} />
          </Box>
          <Typography variant='inherit' noWrap>
            {'Hide this NFT'}
          </Typography>
        </MenuItem>
        {isShowRemoveMenu && (
          <MenuItem
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={handleClickMenuItem(handleClickRemoveNFT)}
          >
            <Box sx={{ marginRight: 2, display: 'flex', alignItems: 'center' }}>
              <Delete fontSize='small' sx={{ color: grey[500] }} />
            </Box>
            <Typography variant='inherit' noWrap>
              {'Remove this NFT'}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )

  const renderNormalMode = () => (
    <Fragment>
      {shouldShowTransferMenu && (
        <Tooltip title={'Transfer'} arrow placement='top'>
          <IconButton
            onClick={handleClickSend}
            onFocus={stopPropagation}
            aria-label='Transfer'
            size='large'
          >
            <Send fontSize='small' sx={{ color: grey[500] }} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={isCopied ? 'Copied!' : 'Copy address'}
        arrow
        placement='top'
        onClose={() => setIsCopied(false)}
        TransitionProps={{ timeout: 0 }}
      >
        <IconButton
          onClick={handleClickCopy}
          onFocus={stopPropagation}
          aria-label='Copy NFT Address'
          size='large'
        >
          <FileCopy fontSize='small' sx={{ color: grey[500] }} />
        </IconButton>
      </Tooltip>
      {chainIdWhitelist.includes(chainId) && (
        <Tooltip title={'Open in Explorer'} arrow placement='top'>
          <IconButton
            onClick={handleClickNFTLink}
            onFocus={stopPropagation}
            aria-label='Link to NFT'
            size='large'
          >
            <OpenInNew fontSize='small' sx={{ color: grey[500] }} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={'Hide this NFT'} arrow placement='top'>
        <IconButton
          onClick={handleClickHideNFT}
          onFocus={stopPropagation}
          aria-label='Hide NFT'
          size='large'
        >
          <VisibilityOff fontSize='small' sx={{ color: grey[500] }} />
        </IconButton>
      </Tooltip>
      {isShowRemoveMenu && (
        <Tooltip title={'Remove this NFT'} arrow placement='top'>
          <IconButton
            onClick={handleClickRemoveNFT}
            onFocus={stopPropagation}
            aria-label='Remove NFT'
            size='large'
          >
            <Delete fontSize='small' sx={{ color: grey[500] }} />
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  )

  return (
    <Box onClick={stopPropagation} sx={{ display: 'flex' }}>
      {isSmallMode ? renderSmallMode() : renderNormalMode()}
    </Box>
  )
}

export default NFTMenu
