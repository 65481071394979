import { Box, CircularProgress } from '@mui/material'
import { Fragment, useContext } from 'react'
import { StateContext } from '../constants/contexts'

const Loading = () => {
  const { loading } = useContext(StateContext)

  if (loading)
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          left: 0,
          top: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 10000,
        }}
      >
        <CircularProgress size={'15vw'} />
      </Box>
    )

  return <Fragment />
}

export default Loading
