export const ACTION_TYPE = {
  setChainId: 'setChainId',
  clearChainId: 'clearChainId',
  setWalletAddress: 'setWalletAddress',
  setPreviewAddress: 'setPreviewAddress',
  setFetchingPercents: 'setFetchingPercents',
  clearFetchingPercents: 'clearFetchingPercents',
  showLoading: 'showLoading',
  hideLoading: 'hideLoading',
  addNFT: 'addNFT',
  hideNFT: 'hideNFT',
  unhideAllNFT: 'unhideAllNFT',
  deleteNFT: 'deleteNFT',
  setExpanded: 'setExpanded',
  changeViewMode: 'changeViewMode',
  showAddNFTDialog: 'showAddNFTDialog',
  hideAddNFTDialog: 'hideAddNFTDialog',
  showHideNFTDialog: 'showHideNFTDialog',
  hideHideNFTDialog: 'hideHideNFTDialog',
  showRemoveNFTDialog: 'showRemoveNFTDialog',
  hideRemoveNFTDialog: 'hideRemoveNFTDialog',
  showTransferDialog: 'showTransferDialog',
  hideTransferDialog: 'hideTransferDialog',
  showImageViewer: 'showImageViewer',
  hideImageViewer: 'hideImageViewer',
  loadNFTState: 'loadNFTState',
  loadViewMode: 'loadViewMode',
  loadPreviewAddress: 'loadPreviewAddress',
}
