import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.showImageViewer: {
      const { images, onClose } = action.payload
      return { isShow: true, images, onClose }
    }
    case ACTION_TYPE.hideImageViewer: {
      return { isShow: false, images: [], onClose: () => {} }
    }
    default: {
      return state
    }
  }
}

const initialState = { isShow: false, images: [], onClose: () => {} }

const initialize = (initialState = {}) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
