import { Box, Typography } from '@mui/material'
import { useCallback, useEffect, useState, useContext, useRef } from 'react'
import { donationAddress } from '../settings'
import {
  getNFTData,
  getPromiseNFTDataList,
  transferNFT,
} from '../utils/ethUtils'
import NFTList from './NFTList'
import NFTCards from './NFTCards'
import { VIEW_MODE } from '../constants/view'
import { DispatchContext, StateContext } from '../constants/contexts'
import { ACTION_TYPE } from '../constants/actionType'
import NFTGalleryAppBar from './NFTGalleryAppBar'
import { getAddress } from 'ethers/lib/utils'
import PreviewTextField from './PreviewTextField'

const donationText = `For supporting, ${donationAddress}.`
const donationThank = 'Copied to clipboard. Thank you for your supporting.'

const Body = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const {
    walletAddress,
    previewAddress,
    chainId,
    nftAddress,
    nftOrder,
    viewMode,
  } = state

  const setFetchingPercents = useCallback(
    fetchingPercents =>
      dispatch({
        type: ACTION_TYPE.setFetchingPercents,
        payload: { fetchingPercents },
      }),
    [dispatch]
  )
  const clearFetchingPercents = useCallback(
    fetchingPercents => dispatch({ type: ACTION_TYPE.clearFetchingPercents }),
    [dispatch]
  )

  const showLoading = () => dispatch({ type: ACTION_TYPE.showLoading })
  const hideLoading = () => dispatch({ type: ACTION_TYPE.hideLoading })

  const hideAddNFTDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideAddNFTDialog }),
    [dispatch]
  )
  const hideImageViewer = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideImageViewer }),
    [dispatch]
  )
  const hideTransferDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideTransferDialog }),
    [dispatch]
  )
  const hideHideNFTDialog = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideHideNFTDialog }),
    [dispatch]
  )

  const [nftList, setNFTList] = useState([])
  const [isShowThank, setIsShowThank] = useState(false)

  const prevNFTAddress = useRef({})

  useEffect(() => {
    setNFTList([])
    setIsShowThank(false)
    hideAddNFTDialog()
    hideImageViewer()
    hideTransferDialog()
    hideHideNFTDialog()
    prevNFTAddress.current = {}
  }, [
    previewAddress,
    hideAddNFTDialog,
    hideImageViewer,
    hideTransferDialog,
    hideHideNFTDialog,
  ])

  const stringNFTAddress = JSON.stringify(nftAddress)
  const stringNFTOrder = JSON.stringify(nftOrder)

  useEffect(() => {
    let canceled = false
    const controller = new AbortController()

    const getAllNFTData = async () => {
      setFetchingPercents(30)

      const showAddressList = nftOrder.filter(
        address => nftAddress[address]?.isShow
      )
      const fetchAddressList = showAddressList.filter(
        address =>
          JSON.stringify(prevNFTAddress.current[address]) !==
          JSON.stringify(nftAddress[address])
      )

      const showIds = Object.fromEntries(
        Object.keys(nftAddress).map(addr => [
          addr,
          nftAddress[addr].showIdList.filter(
            id => !nftAddress[addr].hiddenIdList.includes(id)
          ),
        ])
      )

      let counter = 0
      await Promise.all(
        getPromiseNFTDataList(
          previewAddress,
          fetchAddressList,
          showIds,
          controller.signal
        ).map(p =>
          p.then(data => {
            if (canceled) return

            counter++
            setFetchingPercents(
              Math.ceil((counter * 100) / fetchAddressList.length)
            )

            if (data?.tokens) {
              data.tokens = data.tokens.filter(
                token =>
                  !nftAddress[data.address].hiddenIdList.includes(token.id)
              )
            }

            setNFTList(oldNFTList =>
              showAddressList
                .map(address =>
                  data?.address &&
                  getAddress(address) === getAddress(data.address)
                    ? data
                    : oldNFTList.find(
                        oldData =>
                          getAddress(oldData.address) === getAddress(address)
                      )
                )
                .filter(Boolean)
            )
          })
        )
      )

      if (canceled) return

      clearFetchingPercents()

      setNFTList(oldNFTList =>
        showAddressList
          .map(address =>
            oldNFTList.find(
              oldData => getAddress(oldData.address) === getAddress(address)
            )
          )
          .filter(Boolean)
      )

      prevNFTAddress.current = nftAddress
    }

    getAllNFTData()

    return () => {
      canceled = true
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previewAddress,
    stringNFTAddress,
    stringNFTOrder,
    setFetchingPercents,
    clearFetchingPercents,
  ])

  const onTransfer = async (nftAddressTransfer, tokenId, toAddress, amount) => {
    if (!walletAddress) return

    try {
      showLoading()
      const address = getAddress(nftAddressTransfer)
      await transferNFT(walletAddress, address, tokenId, toAddress, amount)

      const showIds = nftAddress[address].showIdList.filter(
        id => !nftAddress[address].hiddenIdList.includes(id)
      )

      const nftData = await getNFTData(walletAddress, address, showIds)

      nftData.tokens = nftData.tokens.filter(
        token => !nftAddress[nftData.address].hiddenIdList.includes(token.id)
      )

      setNFTList(oldNFTList =>
        oldNFTList
          .map(nft =>
            nft?.address && getAddress(nft.address) === address ? nftData : nft
          )
          .filter(nft => nft?.tokens?.length > 0)
      )
    } finally {
      hideLoading()
    }
  }

  const handleClickDonation = async () => {
    try {
      await navigator.clipboard.writeText(donationAddress)
      setIsShowThank(true)
      setTimeout(() => setIsShowThank(false), 2500)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <NFTGalleryAppBar />
      {!!chainId && <PreviewTextField />}
      <Box sx={{ width: 1, p: 1 }}>
        {viewMode === VIEW_MODE.CARD && (
          <NFTCards nftList={nftList} onTransfer={onTransfer} />
        )}
        {viewMode === VIEW_MODE.LIST && (
          <NFTList nftList={nftList} onTransfer={onTransfer} />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          flexGrow: 1,
          p: 2,
        }}
      >
        <Typography
          variant='body2'
          onClick={handleClickDonation}
          color='textSecondary'
          noWrap
          align='right'
          sx={
            !isShowThank && {
              cursor: 'pointer',
              '&:hover': {
                color: theme => theme.palette.primary.main,
              },
            }
          }
        >
          {isShowThank ? donationThank : donationText}
        </Typography>
      </Box>
    </Box>
  )
}

export default Body
