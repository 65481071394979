import { getAddress } from 'ethers/lib/utils'
import {
  auroraLogo,
  bitkubLogo,
  bscLogo,
  ethereumLogo,
  fantomLogo,
  harmonyLogo,
  jfinLogo,
  kccLogo,
  maticLogo,
  metaLogo,
  reiLogo,
} from './assets'
import { TOKEN_TYPE } from './constants/contract'

export const donationAddress = getAddress(
  '0x75F3Cf328E6347d74D3C94db47DC5e712360FC2B'
)

export const corsAnywhere =
  'https://cf_cors_proxy.mickeytc.workers.dev/corsproxy/?apiurl='

export const ryoiiNFTAddress = getAddress(
  '0xee99cf2611277953300c794fc25f20c21fc17ac4'
)

export const defaultNFTAddressList = {
  // MetaChain
  17: [
    '0xb997488705432B9FBd13bb6688C0Ab520EC13986', // Metaverse Thailand Land NFT
  ].map(getAddress),
  // Bitkub Chain
  96: [
    '0x7084AEEE0156c3E1258cDC9B63123d4eab57C361', // Bitkub Official
    '0x8523Aee1e5e8eE3b00a050883E69A14dc72fC0d8', // Miss Universe Thailand
    '0xc6Ce0974DDC4cE4B76d9190dd8d48866A9976DE9', // Thaileague-platform
    '0x5d29c11405A75DA5FCE4A885cC9793aff7F494dB', // Bitkub NFT Event
    '0x187F89Ef4Bc68Ee80bc7048839f222098BCA7178', // Topp Jirayut
    '0xe88Dc0E18673A100478cfdF195e51217b3629F4c', // Thai Talisman Official Store
    '0x7dAC9892eb121caEF5562Fe9B36B1900c4a89425', // Bie The Ska
    '0xaB2dd323bd91401150504bf60B88F6CA0e76Ca1F', // Kaykai Salaider
    '0x2929981D3DDC83c3802F31c9325adFFA7508e953', // Mootae World
    '0xfF2f5342FF8fFfA17a04aA5CbC162001A25B71a7', // PunkKub
    '0xFB283132B1d97e3Cd17C3E98f05CB6E466D68683', // Pleum VRZO
    '0xd08Ac40b3a0A7fb20b026A3b6Cd5D7cFadc3d6f5', // Morning Moon Item
    '0x2b31015616D4646c5e4e00E23C597c064d9541df', // Happy Treasure Hunt
    '0xA2801e5Ab284851822FC20f3D655953899d22545', // Tum Ulit
    '0x8B3b005Fe2AFC701D18d93E413b43afEeE6B5c6d', // Bacon Time Official Store
    '0xb03006291efff1d3f3D46BEa087899aA1bdF57f3', // Bar B Q Plaza
    '0xc2567B8baf88A8cbEf527577306AE49EDDf992f6', // CryptoRonin
    '0x10736c67BCa17aea4b2ac364Fee9A09050cFF3B7', // PUBG NFT
    '0x5B5F5DD97e9ce4f70486cc113660634ce5a94838', // Retouchpls
    '0x5D2d847fabDb3d8f6063bB4Bc836a8059AA1A34B', // Dicer
    '0xecC2570f8826a5Fdfd19A1168b670333De879d6E', // DiceKingdom Item
    '0x86553970948eaeaAf9ad08Be0B98158F302197EC', // Kub Name Service
  ].map(getAddress),
  // Ethereum
  1: [
    '0x67095DD776f6ca6915dDD39d5F3d88d254Bf9BF1', // Evolution of Gamers - Thailand Game Show
  ].map(getAddress),
  // Binance Smart Chain
  56: [
    '0x24fADCEfd2117719C4078C08b352b22C74542418', // Mobox Avatar
    '0xC3dDDF56dCf30635760F2532950d94AD80AE98Be', // WWIN Subanaraja Garuda Gold Model
    '0x186aeb1449f48f477a78c24ae7d6aa5941e9a80c', // KillSwitch NFT
    '0xe85d7b8f4c0c13806e158a1c9d7dcb33140cdc46', // Alpaca NFT
    '0x9f0225d5C92B9cee4024F6406c4f13e546Fd91A8', // Non-Fungible Baby
    '0xdf7952b35f24acf7fc0487d01c8d5690a60dba07', // Pancake Bunnies
    '0x1784daab31d1d0fc20e3bc1e015088f53af29bad', // SupermarketToken FoodCourt
  ].map(getAddress),
  // KCC
  321: [
    '0x624423e47417384f63286751fd30094a1b0394d6', // KillSwitch NFT on KCC
  ].map(getAddress),
}

export const transferBlackList = []

export const chainExplorerURL = {
  // MetaChain
  17: (address, type, walletAddress) =>
    `https://exp.metachain.asia/tokens/${address}`,
  // Bitkub Chain
  96: (address, type, walletAddress) => `https://bkcscan.com/tokens/${address}`,
  // Binance Smart Chain
  56: (address, type, walletAddress) =>
    type === TOKEN_TYPE.ERC1155
      ? `https://bscscan.com/address/${address}`
      : `https://bscscan.com/token/${address}${
          walletAddress ? `?a=${walletAddress}` : ''
        }`,
  // Ethereum
  1: (address, type, walletAddress) =>
    type === TOKEN_TYPE.ERC1155
      ? `https://etherscan.io/address/${address}`
      : `https://etherscan.io/token/${address}${
          walletAddress ? `?a=${walletAddress}` : ''
        }`,
  // Matic
  137: (address, type, walletAddress) =>
    type === TOKEN_TYPE.ERC1155
      ? `https://polygonscan.com/address/${address}`
      : `https://polygonscan.com/token/${address}${
          walletAddress ? `?a=${walletAddress}` : ''
        }`,
  // Fantom Opera
  250: (address, type, walletAddress) =>
    type === TOKEN_TYPE.ERC1155
      ? `https://ftmscan.com/address/${address}`
      : `https://ftmscan.com/token/${address}${
          walletAddress ? `?a=${walletAddress}` : ''
        }`,
  // Harmony Mainnet
  1666600000: (address, type, walletAddress) =>
    `https://explorer.harmony.one/address/${address}`,
  1666600001: (address, type, walletAddress) =>
    `https://explorer.harmony.one/address/${address}`,
  1666600002: (address, type, walletAddress) =>
    `https://explorer.harmony.one/address/${address}`,
  1666600003: (address, type, walletAddress) =>
    `https://explorer.harmony.one/address/${address}`,
  // Rei Chain
  55555: (address, type, walletAddress) =>
    `https://reiscan.com/token/${address}`,
  // KCC
  321: (address, type, walletAddress) =>
    `https://explorer.kcc.io/token/${address}`,
  // Aurora Chain
  1313161554: (address, type, walletAddress) =>
    type === TOKEN_TYPE.ERC1155
      ? `https://aurorascan.dev/address/${address}`
      : `https://aurorascan.dev/token/${address}${
          walletAddress ? `?a=${walletAddress}` : ''
        }`,
}

export const chainLogo = {
  17: metaLogo,
  96: bitkubLogo,
  56: bscLogo,
  1: ethereumLogo,
  137: maticLogo,
  250: fantomLogo,
  1666600000: harmonyLogo,
  1666600001: harmonyLogo,
  1666600002: harmonyLogo,
  1666600003: harmonyLogo,
  55555: reiLogo,
  321: kccLogo,
  1313161554: auroraLogo,
  3501: jfinLogo,
}

export const chainIdWhitelist = Object.keys(chainExplorerURL).map(Number)
