import { Fragment, useCallback, useContext, useEffect } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'

const viewModeStorageKey = 'viewMode'
const previewAddressStorageKey = 'previewAddress'
const nftStateStorageKey = 'nftStage'

const LocalStorage = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { previewAddress, nftAddress, nftOrder, expanded, viewMode, chainId } =
    state

  const stringNFTAddress = JSON.stringify(nftAddress)
  const stringNFTOrder = JSON.stringify(nftOrder)
  const stringExpanded = JSON.stringify(expanded)

  const loadStorage = useCallback(() => {
    const viewMode = window.localStorage.getItem(viewModeStorageKey)
    dispatch({
      type: ACTION_TYPE.loadViewMode,
      payload: { viewMode },
    })

    const previewAddress = window.localStorage.getItem(previewAddressStorageKey)
    dispatch({
      type: ACTION_TYPE.loadPreviewAddress,
      payload: { previewAddress },
    })

    const nftStage = window.localStorage.getItem(nftStateStorageKey)
    const parsed = nftStage ? JSON.parse(nftStage) : {}
    const payload = parsed[chainId] ?? {}
    dispatch({
      type: ACTION_TYPE.loadNFTState,
      payload: { ...payload, chainId },
    })
  }, [dispatch, chainId])

  useEffect(() => {
    if (!chainId) return

    loadStorage()

    window.addEventListener('storage', loadStorage)

    return () => window.removeEventListener('storage', loadStorage)
  }, [loadStorage, chainId])

  useEffect(() => {
    if (!chainId) return
    window.localStorage.setItem(viewModeStorageKey, viewMode)
  }, [viewMode, chainId])

  useEffect(() => {
    if (!chainId) return
    window.localStorage.setItem(previewAddressStorageKey, previewAddress)
  }, [previewAddress, chainId])

  useEffect(() => {
    if (!chainId) return

    const localData = window.localStorage.getItem(nftStateStorageKey)
    const oldData = localData ? JSON.parse(localData) : {}
    const newData = {
      ...oldData,
      [chainId]: {
        nftAddress,
        nftOrder,
        expanded,
      },
    }
    window.localStorage.setItem(nftStateStorageKey, JSON.stringify(newData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, stringNFTAddress, stringNFTOrder, stringExpanded])

  return <Fragment />
}

export default LocalStorage
