import { Fragment, useContext } from 'react'
import { LinearProgress } from '@mui/material'
import { StateContext } from '../constants/contexts'

const Fetching = () => {
  const { fetchingPercents } = useContext(StateContext)

  if (fetchingPercents > 0 && fetchingPercents < 100) {
    return (
      <LinearProgress
        variant='determinate'
        value={fetchingPercents}
        sx={{
          width: 1,
          position: 'fixed',
          top: 0,
          zIndex: 2000,
        }}
      />
    )
  }

  return <Fragment />
}

export default Fetching
