import { ACTION_TYPE } from '../constants/actionType'
import { VIEW_MODE } from '../constants/view'

const initialState = VIEW_MODE.CARD

const initialize = (initialState = '') => initialState

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.changeViewMode: {
      const { viewMode } = action.payload
      return viewMode
    }
    case ACTION_TYPE.loadViewMode: {
      const { viewMode } = action.payload
      return initialize(viewMode || initialState)
    }
    default: {
      return state
    }
  }
}

const reducerArgs = [reducer, initialState]

export default reducerArgs
