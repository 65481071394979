import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.setPreviewAddress: {
      const { previewAddress } = action.payload
      return previewAddress
    }
    case ACTION_TYPE.loadPreviewAddress: {
      const { previewAddress } = action.payload
      return initialize(previewAddress || initialState)
    }
    default: {
      return state
    }
  }
}

const initialState = ''

const initialize = (initialState = '') => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
