import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.showRemoveNFTDialog: {
      const { data, onRemove, onClose } = action.payload
      return { isShow: true, data, onRemove, onClose }
    }
    case ACTION_TYPE.hideRemoveNFTDialog: {
      return {
        isShow: false,
        data: {},
        onRemove: () => {},
        onClose: () => {},
      }
    }
    default: {
      return state
    }
  }
}

const initialState = {
  isShow: false,
  data: {},
  onRemove: () => {},
  onClose: () => {},
}

const initialize = (initialState = {}) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
