import { getAddress } from 'ethers/lib/utils'
import { ACTION_TYPE } from '../constants/actionType'
import { defaultNFTAddressList } from '../settings'

const initialState = []

const initialize = (initialState = {}, chainId = 0) => [
  ...new Set([...initialState, ...(defaultNFTAddressList[chainId] || [])]),
]

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.addNFT: {
      const { nftAddress } = action.payload
      const address = getAddress(nftAddress)

      return [...new Set([address, ...state])]
    }
    case ACTION_TYPE.deleteNFT: {
      const { nftAddress, id } = action.payload
      if (id) return state

      return state.filter(addr => getAddress(addr) !== getAddress(nftAddress))
    }
    case ACTION_TYPE.loadNFTState: {
      const { nftOrder, chainId } = action.payload
      return initialize(nftOrder || initialState, chainId)
    }
    default: {
      return state
    }
  }
}

const reducerArgs = [reducer, initialState]

export default reducerArgs
