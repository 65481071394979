import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useEffect, useContext, useRef } from 'react'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import { StateContext } from '../constants/contexts'
import { TOKEN_TYPE } from '../constants/contract'
import {
  formatAddressField,
  formatAmountField,
  isImageURL,
  isValidAddress,
} from '../utils/utils'

const TransferDialog = () => {
  const { transferDialog } = useContext(StateContext)
  const {
    data: { nft = {}, tokenIndex },
    isShow,
    onTransfer,
    onClose,
  } = transferDialog

  const [toAddress, setToAddress] = useState('')
  const [addressErrorText, setAddressErrorText] = useState('')
  const [amount, setAmount] = useState('')
  const [amountErrorText, setAmountErrorText] = useState('')

  const walletFieldRef = useRef(null)

  useEffect(() => {
    setToAddress('')
    setAddressErrorText('')
    setAmount('')
    setAmountErrorText('')
  }, [isShow])

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        walletFieldRef?.current?.focus()
      }, 300)
    }
  }, [isShow])

  const { type, address, name, tokens } = nft
  const token = nft.token || tokens?.[tokenIndex] || {}
  const { id, name: tokenName, balance, decimals } = token

  const handleChangeToAddress = e => {
    const text = e.target.value
    setToAddress(formatAddressField(text))
    setAddressErrorText('')
  }

  const handleChangeAmount = e => {
    const text = e.target.value
    setAmount(formatAmountField(text))
    setAmountErrorText('')
  }

  const onClickTransfer = async () => {
    if (!toAddress) {
      setAddressErrorText('Address is required.')
      return
    }
    if (!isValidAddress(toAddress)) {
      setAddressErrorText('Incorrect address format.')
      return
    }
    try {
      if (type === TOKEN_TYPE.ERC721) {
        await onTransfer(address, token.id, toAddress)
        return
      }
      if (type === TOKEN_TYPE.ERC1155) {
        if (!amount) {
          setAmountErrorText('Amount is required.')
          return
        }

        try {
          const parsedAmount = parseUnits(amount, decimals)
          if (parsedAmount.lte(0)) {
            setAmountErrorText('Amount must more than zero.')
            return
          }
          if (parsedAmount.gt(balance)) {
            setAmountErrorText('Insufficient tokens')
            return
          }
        } catch (err) {
          console.error(err)
          setAmountErrorText('Invalid amount.')
          return
        }
        await onTransfer(
          address,
          token.id,
          toAddress,
          parseUnits(amount, decimals)
        )
        return
      }
    } catch (err) {
      console.error(err)
      // setAddressErrorText(err.data?.message || err.message)
    }
  }

  const renderMedia = token => {
    const url = token.animation_url || token.gif_url || token.image || ''

    if ((!isImageURL(url) && ReactPlayer.canPlay(url)) || token.animation_url) {
      return (
        <Box sx={{ height: '25vmin', m: 2 }}>
          <ReactPlayer
            url={url}
            controls
            height='100%'
            width='100%'
            playing
            loop
            muted
          />
        </Box>
      )
    }

    return url ? (
      <Box
        component='img'
        sx={{ height: '25vmin', m: 2 }}
        src={url}
        alt={token.name}
      />
    ) : null
  }

  return (
    <Dialog
      open={isShow}
      onClose={onClose}
      aria-labelledby='transfer-dialog-title'
      transitionDuration={{ enter: 300, exit: 0 }}
    >
      <DialogTitle id='transfer-dialog-title'>Transfer</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!!(name || address) && (
            <Typography
              noWrap
              variant='body1'
              sx={{ textAlign: 'center', width: 1 }}
            >
              {name || address}
            </Typography>
          )}
          {renderMedia(token)}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {!!tokenName && (
              <Typography variant='body1'>{tokenName}</Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 1,
              }}
            >
              <Typography variant='body1'>#</Typography>
              <Typography variant='body1'>{id}</Typography>
            </Box>
            {!!type && type !== TOKEN_TYPE.ERC721 && !!balance && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 1,
                }}
              >
                <Typography variant='body1'>x</Typography>
                <Typography variant='body1'>
                  {formatUnits(balance, decimals)}
                </Typography>
              </Box>
            )}
          </Box>
          <TextField
            inputRef={walletFieldRef}
            sx={{ marginTop: 2 }}
            fullWidth
            label='Wallet Address'
            placeholder='Address'
            variant='outlined'
            value={toAddress}
            onChange={handleChangeToAddress}
            error={!!addressErrorText}
            helperText={addressErrorText}
          />
          {type === TOKEN_TYPE.ERC1155 && (
            <TextField
              sx={{ marginTop: 2 }}
              fullWidth
              label='Amount'
              placeholder='Amount'
              variant='outlined'
              value={amount}
              onChange={handleChangeAmount}
              error={!!amountErrorText}
              helperText={amountErrorText}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={onClickTransfer}
          color='primary'
          disabled={!!addressErrorText || !!amountErrorText}
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransferDialog
