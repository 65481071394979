import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { Close } from '@mui/icons-material'
import { useCallback, useContext } from 'react'
import ReactPlayer from 'react-player/lazy'
import { formatUnits, getAddress } from 'ethers/lib/utils'
import { formatTokenID, isImageURL, nftListToColumn } from '../utils/utils'
import { noImage } from '../assets'
import NFTMenu from './NFTMenu'
import { TOKEN_TYPE } from '../constants/contract'
import { DispatchContext, StateContext } from '../constants/contexts'
import { ACTION_TYPE } from '../constants/actionType'

const NFTCards = props => {
  const { walletAddress, previewAddress } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const showImageViewer = useCallback(
    ({ images, onClose }) =>
      dispatch({
        type: ACTION_TYPE.showImageViewer,
        payload: { images, onClose },
      }),
    [dispatch]
  )
  const hideImageViewer = useCallback(
    () => dispatch({ type: ACTION_TYPE.hideImageViewer }),
    [dispatch]
  )

  const { nftList, onTransfer } = props

  const nColumn =
    [
      useMediaQuery(theme => theme.breakpoints.up('sm')),
      useMediaQuery(theme => theme.breakpoints.up('md')),
      useMediaQuery(theme => theme.breakpoints.up('lg')),
      useMediaQuery(theme => theme.breakpoints.up('xl')),
    ].filter(Boolean).length + 2

  const onClickImage = (name, image) => () => {
    showImageViewer({
      images: [{ src: image, alt: name, downloadUrl: image }],
      onClose: () => hideImageViewer(),
    })
  }

  const renderMedia = nft => {
    const token = nft.token
    const url = token.animation_url || token.gif_url || token.image || ''

    if ((!isImageURL(url) && ReactPlayer.canPlay(url)) || token.animation_url) {
      return (
        <Box
          sx={{
            height: `${Math.floor(96 / nColumn)}vw`,
          }}
        >
          <ReactPlayer
            url={url}
            controls
            height='100%'
            width='100%'
            playing
            loop
            muted
          />
        </Box>
      )
    }

    return url ? (
      <CardMedia
        sx={{
          height: `${Math.floor(96 / nColumn)}vw`,
        }}
        image={url}
        alt={token.name || nft.name || nft.symbol || ''}
        title={`${nft.address}`}
      />
    ) : (
      <Box
        sx={{
          height: `${Math.floor(96 / nColumn)}vw`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: grey[800],
        }}
      >
        <Box
          component='img'
          src={noImage}
          alt={token.name || nft.name || nft.symbol || ''}
          sx={{
            height: `${Math.floor(46 / nColumn)}vw`,
          }}
        />
      </Box>
    )
  }

  const transferable = (type, token) => {
    try {
      if (type === TOKEN_TYPE.ERC721) {
        return (
          !!walletAddress &&
          !!token?.owner &&
          getAddress(walletAddress) === getAddress(token.owner)
        )
      }
      if (type === TOKEN_TYPE.ERC1155) {
        return (
          !!walletAddress &&
          !!previewAddress &&
          token?.balance?.gt(0) &&
          getAddress(walletAddress) === getAddress(previewAddress)
        )
      }

      return false
    } catch (err) {
      return false
    }
  }

  const shouldShowRemoveMenu = (address, tokenId, enumerable) =>
    (!!previewAddress && !enumerable) || !previewAddress

  const nftCard = nft => {
    const token = nft.token
    const tokenImage = token.gif_url || token.image || ''

    return (
      <Card
        sx={{
          margin: 1,
          background: grey[800],
          borderRadius: '1.5em',
          width: `${Math.floor(96 / nColumn)}vw`,
        }}
        key={`${nft.address}-${token.id}`}
      >
        <CardActionArea
          onClick={
            tokenImage &&
            (isImageURL(tokenImage) || !ReactPlayer.canPlay(tokenImage))
              ? onClickImage(token.name, tokenImage)
              : null
          }
        >
          {renderMedia(nft)}
          {!!nft.type && nft.type !== TOKEN_TYPE.ERC721 && token.balance.gt(0) && (
            <Box
              sx={{
                padding: 2,
                background: theme => theme.palette.primary.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '2em',
                minWidth: '3em',
                borderRadius: '1.5em',
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close
                fontSize='small'
                sx={{ color: theme => theme.palette.primary.contrastText }}
              />
              <Typography
                variant='h6'
                sx={{ color: theme => theme.palette.primary.contrastText }}
              >
                {formatUnits(token.balance, token.decimals)}
              </Typography>
            </Box>
          )}
        </CardActionArea>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: '16px!important',
          }}
        >
          <Tooltip title={token.id} enterDelay={1200} placement='bottom-start'>
            <Typography variant='h5' color='secondary' sx={{ mr: 2 }}>
              {formatTokenID(token.id)}
            </Typography>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <Tooltip
              title={nft.symbol || nft.address || ''}
              enterDelay={1200}
              placement='top-start'
            >
              <Typography noWrap variant='body1'>
                {nft.symbol || nft.address || ''}
              </Typography>
            </Tooltip>
            <Tooltip
              title={token.name || nft.name || ''}
              enterDelay={1200}
              placement='bottom'
            >
              <Typography noWrap variant='body2' color='textSecondary'>
                {token.name || nft.name || ''}
              </Typography>
            </Tooltip>
          </Box>
          <NFTMenu
            isSmallMode={true}
            isShowTransferMenu={transferable(nft.type, token)}
            nft={nft}
            onTransfer={onTransfer}
            isShowRemoveMenu={shouldShowRemoveMenu(
              nft.address,
              token.id,
              nft.enumerable
            )}
          />
        </CardContent>
      </Card>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {nftListToColumn(nftList, nColumn).map((row, index) => (
        <Box
          key={`${index}`}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {row.map(nft => nftCard(nft))}
        </Box>
      ))}
    </Box>
  )
}

export default NFTCards
