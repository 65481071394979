import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.showHideNFTDialog: {
      const { data, onHide, onClose } = action.payload
      return { isShow: true, data, onHide, onClose }
    }
    case ACTION_TYPE.hideHideNFTDialog: {
      return {
        isShow: false,
        data: {},
        onHide: () => {},
        onClose: () => {},
      }
    }
    default: {
      return state
    }
  }
}

const initialState = {
  isShow: false,
  data: {},
  onHide: () => {},
  onClose: () => {},
}

const initialize = (initialState = {}) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
