import { useRef } from 'react'

export const useCombinedReducer = combinedReducers => {
  const state = Object.keys(combinedReducers).reduce(
    (acc, key) => ({ ...acc, [key]: combinedReducers[key][0] }),
    {}
  )

  const { current: dispatch } = useRef(action =>
    Object.keys(combinedReducers)
      .map(key => combinedReducers[key][1])
      .forEach(fn => fn(action))
  )

  return [state, dispatch]
}
