import { getAddress } from 'ethers/lib/utils'
import { ACTION_TYPE } from '../constants/actionType'
import { defaultNFTAddressList } from '../settings'

const initialState = {}

const initialize = (initialState = {}, chainId = 0) =>
  (defaultNFTAddressList[chainId] || []).reduce(
    (prev, address) => ({
      [getAddress(address)]: true,
      ...prev,
    }),
    initialState
  )

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.setExpanded: {
      const { nftAddressList, isExpanded } = action.payload
      const expandedList = nftAddressList.reduce(
        (list, address) => ({ ...list, [address]: isExpanded }),
        {}
      )
      return { ...state, ...expandedList }
    }
    case ACTION_TYPE.addNFT: {
      const { nftAddress } = action.payload
      return { ...state, [getAddress(nftAddress)]: true }
    }
    case ACTION_TYPE.deleteNFT: {
      const { nftAddress, id } = action.payload
      if (id) return state

      return Object.fromEntries(
        Object.entries(state).filter(
          e => getAddress(e[0]) !== getAddress(nftAddress)
        )
      )
    }
    case ACTION_TYPE.loadNFTState: {
      const { expanded, chainId } = action.payload
      return initialize(expanded || initialState, chainId)
    }
    default: {
      return state
    }
  }
}

const reducerArgs = [reducer, initialState]

export default reducerArgs
