import { getAddress } from 'ethers/lib/utils'
import { ACTION_TYPE } from '../constants/actionType'
import { defaultNFTAddressList } from '../settings'

const initialState = {}

const initialize = (initialState = {}, chainId = 0) =>
  (defaultNFTAddressList[chainId] || []).reduce(
    (prev, address) => ({
      [getAddress(address)]: {
        isShow: true,
        showIdList: [],
        hiddenIdList: [],
      },
      ...prev,
    }),
    initialState
  )

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.addNFT: {
      const { nftAddress, idList = [] } = action.payload
      const address = getAddress(nftAddress)

      const showIdList = [
        ...new Set([...(state[address]?.showIdList || []), ...idList]),
      ].sort((a, b) => a - b)
      const hiddenIdList =
        idList.length > 0
          ? (state[address]?.hiddenIdList || []).filter(
              id => !idList.includes(id)
            )
          : []
      const isShow = true

      const nextState = {
        ...state,
        [address]: {
          isShow,
          showIdList,
          hiddenIdList,
        },
      }

      return nextState
    }
    case ACTION_TYPE.hideNFT: {
      const { nftAddress, id } = action.payload
      const address = getAddress(nftAddress)

      const isShow = !!id
      const showIdList = state[address]?.showIdList || []
      const hiddenIdList = id
        ? [...new Set([...(state[address]?.hiddenIdList || []), id])].sort(
            (a, b) => a - b
          )
        : []

      const nextState = {
        ...state,
        [address]: {
          isShow,
          showIdList,
          hiddenIdList,
        },
      }

      return nextState
    }
    case ACTION_TYPE.unhideAllNFT: {
      const nextState = {}

      Object.keys(state).forEach(address => {
        const addr = getAddress(address)
        nextState[addr] = {
          isShow: true,
          showIdList: state[addr].showIdList,
          hiddenIdList: [],
        }
      })

      return nextState
    }
    case ACTION_TYPE.deleteNFT: {
      const { nftAddress, id } = action.payload
      const address = getAddress(nftAddress)

      if (!id) {
        return Object.fromEntries(
          Object.entries(state).filter(e => getAddress(e[0]) !== address)
        )
      }

      const isShow = true
      const showIdList = (state[address]?.showIdList || []).filter(
        showId => showId !== id
      )
      const hiddenIdList = (state[address]?.hiddenIdList || []).filter(
        hiddenId => hiddenId !== id
      )

      return {
        ...state,
        [address]: {
          isShow,
          showIdList,
          hiddenIdList,
        },
      }
    }
    case ACTION_TYPE.loadNFTState: {
      const { nftAddress, chainId } = action.payload
      return initialize(nftAddress || initialState, chainId)
    }
    default: {
      return state
    }
  }
}

const reducerArgs = [reducer, initialState]

export default reducerArgs
