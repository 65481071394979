import { BigNumber } from 'ethers'
import { getAddress, hexZeroPad } from 'ethers/lib/utils'

export const ipfsToHttps = ipfsUrl => {
  if (typeof ipfsUrl !== 'string' && !(ipfsUrl instanceof String))
    return ipfsUrl
  return ipfsUrl?.replace('ipfs://', 'https://dweb.link/ipfs/')
  // return ipfsUrl?.replace('ipfs://', 'https://ipfs.io/ipfs/')
}

export const replaceID = id => text => {
  if (typeof text !== 'string' && !(text instanceof String)) return text

  let bigNumId
  try {
    bigNumId = BigNumber.from(id)
  } catch (err) {
    console.error(err)
    return text
  }

  const formattedID = hexZeroPad(bigNumId.toHexString(), 64)
    .replace('0x', '')
    .toLowerCase()
  return text?.replace(/\{id\}/g, formattedID)
}

export const shortenAddress = address => {
  try {
    const addr = getAddress(address)
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`
  } catch (err) {
    return address || ''
  }
}

export const attributesToShow = attributes => {
  if (!attributes) return []

  if (Array.isArray(attributes)) {
    return attributes
      .map(attribute => {
        if (attribute.trait_type) {
          return attribute.display_type === 'date'
            ? `${attribute.trait_type} : ${new Date(
                Number(attribute.value) * 1000
              ).toLocaleString()}`
            : `${attribute.trait_type} : ${attribute.value}`
        }

        if (attribute.label) {
          return `${attribute.label} : ${attribute.value}`
        }

        return ''
      })
      .filter(Boolean)
  }

  return Object.entries(attributes).map(attribute => attribute.join(' : '))
}

export const formatAddressField = text => {
  let formatted = text.replace(/[^x0-9a-fA-F]/g, '')
  const match = formatted.match(/^([^x]*x)(.*)$/)
  if (match) {
    return match[1] + (match[2]?.replace(/x/g, '') || '')
  }
  return formatted
}

export const isValidAddress = text => {
  try {
    return !!getAddress(text)
  } catch (err) {
    console.error(err)
    return false
  }
}

export const formatTokenIDsField = text => {
  let formatted = text
    .replace(/[^\d,-]/g, '')
    .replace(/^,/, '')
    .replace(/^-/, '')
    .replace(/[,]{2,}/g, ',')
    .replace(/[-]{2,}/g, '-')
    .replace(/-,/g, ',')
    .replace(/,-/g, ',')
    .split(',')
    .map(a => {
      const arr = a.split('-')
      return (arr.length > 2 ? arr.slice(0, 2) : arr).join('-')
    })
    .join(',')

  return formatted
}

export const splitTokenIds = tokenIds =>
  tokenIds
    ? [
        ...new Set(
          tokenIds.split(',').reduce((arr, n) => {
            const a = n.split('-')
            if (a.length === 1) {
              return [...arr, a[0]]
            }
            const [l, u] = a.map(a => Number.parseInt(a)).sort((a, b) => a - b)
            const range = [...new Array(u - l + 1).keys()]
              .map(i => i + l)
              .map(a => a.toString())
            return [...arr, ...range]
          }, [])
        ),
      ].sort((a, b) => a - b)
    : []

export const formatAmountField = text => {
  let formatted = text.replace(/[^.0-9]/g, '')
  const match = formatted.match(/^([^.]*\.)(.*)$/)
  if (match) {
    return match[1] + (match[2]?.replace(/\./g, '') || '')
  }
  return formatted
}

export const nftListToColumn = (nftList, nColumn = 4) => {
  const nft = nftList.flatMap(nft =>
    nft.tokens?.map(token => {
      const { tokens, ...rest } = nft
      return {
        ...rest,
        token,
      }
    })
  )

  if (nColumn < 1) return nft

  const nRow = Math.ceil(nft.length / nColumn)
  return [...new Array(nRow).keys()].map(r =>
    nft.slice(r * nColumn, Math.min((r + 1) * nColumn, nft.length))
  )
}

export const isImageURL = url => {
  if (!url) return

  const fileExtension = new URL(url).pathname.split('.').pop()?.toLowerCase()
  const imageFormatList = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jpe',
    'jif',
    'jfif',
    'png',
    'svg',
    'webp',
  ]
  return imageFormatList.includes(fileExtension)
}

export const formatTokenID = tokenID => {
  const id = tokenID.toString()
  return id.length >= 8 ? `${id.slice(0, 3)}...${id.slice(-3)}` : id
}
