import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useContext } from 'react'
import ReactPlayer from 'react-player'
import { StateContext } from '../constants/contexts'
import { isImageURL } from '../utils/utils'

const RemoveNFTDialog = () => {
  const { removeNFTDialog } = useContext(StateContext)
  const {
    data: { nft = {}, tokenIndex },
    isShow,
    onRemove,
    onClose,
  } = removeNFTDialog

  const { tokens, symbol, name, address } = nft
  const token = nft.token || tokens?.[tokenIndex] || {}

  const onClickRemove = () => {
    onRemove(address, token.id)
  }

  const getTitle = () => {
    if (symbol && name) return `${symbol} : ${name}`
    if (symbol) return symbol
    if (name) return name
    return address
  }

  const renderMedia = token => {
    const url = token.animation_url || token.gif_url || token.image || ''

    if ((!isImageURL(url) && ReactPlayer.canPlay(url)) || token.animation_url) {
      return (
        <Box sx={{ height: '25vmin', m: 2 }}>
          <ReactPlayer
            url={url}
            controls
            height='100%'
            width='100%'
            playing
            loop
            muted
          />
        </Box>
      )
    }

    return url ? (
      <Box
        component='img'
        sx={{ height: '25vmin', m: 2 }}
        src={url}
        alt={token.name}
      />
    ) : null
  }

  return (
    <Dialog
      open={isShow}
      onClose={onClose}
      aria-labelledby='remove-nft-dialog-title'
      aria-describedby='remove-nft-dialog-description'
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle id='remove-nft-dialog-title'>{`Remove?`}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Typography variant='body1' align='center' sx={{ mb: 1 }}>
            {getTitle()}
          </Typography>
          {renderMedia(token)}
          {!!token.id && (
            <Typography
              variant='body1'
              align='center'
            >{`#${token.id}`}</Typography>
          )}
        </Box>
        <DialogContentText id='remove-nft-dialog-description'>
          {`You can add this token back in the future by going to “Add NFT”.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={onClickRemove} color='error'>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveNFTDialog
