import axios from 'axios'
import { BigNumber, ethers } from 'ethers'
import ERC721ABI from '../abi/ERC721'
import ERC1155ABI from '../abi/ERC1155'
import { corsAnywhere, ryoiiNFTAddress } from '../settings'
import { ipfsToHttps, replaceID } from './utils'
import { INTERFACE_ID, TOKEN_TYPE } from '../constants/contract'
import { getAddress } from 'ethers/lib/utils'

let provider
let signer

export const connectWallet = async () => {
  if (!window.ethereum) throw new Error('Not found ethereum.')

  provider = new ethers.providers.Web3Provider(window.ethereum)

  await provider.send('eth_requestAccounts', [])
}

export const getWallet = async () => {
  if (!window.ethereum) throw new Error('Not found ethereum.')

  provider = new ethers.providers.Web3Provider(window.ethereum)

  signer = provider.getSigner()

  const walletAddress = await signer.getAddress()
  const chainId = (await provider.getNetwork())?.chainId || 0
  return { walletAddress, chainId }
}

export const isSupportedNFT = async (nftAddress, nftContract) => {
  try {
    const contract =
      nftContract || new ethers.Contract(nftAddress, ERC721ABI, provider)
    return (
      (await contract.supportsInterface(INTERFACE_ID.ERC721)) ||
      (await contract.supportsInterface(INTERFACE_ID.ERC1155)) ||
      (await contract.supportsInterface(INTERFACE_ID.KAP721))
    )
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isSupportEnumerable = async (nftAddress, nftContract) => {
  try {
    const contract =
      nftContract || new ethers.Contract(nftAddress, ERC721ABI, provider)
    return (
      (await contract.supportsInterface(INTERFACE_ID.ERC721Enumerable)) ||
      (await contract.supportsInterface(INTERFACE_ID.KAP721Enumerable))
    )
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isSupportMetadata = async (nftAddress, nftContract) => {
  try {
    const contract =
      nftContract || new ethers.Contract(nftAddress, ERC721ABI, provider)
    return (
      (await contract.supportsInterface(INTERFACE_ID.ERC721Metadata)) ||
      (await contract.supportsInterface(INTERFACE_ID.KAP721Metadata)) ||
      (await contract.supportsInterface(INTERFACE_ID.KAP721MetadataOld))
    )
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isERC721 = async (nftAddress, nftContract) => {
  try {
    const contract =
      nftContract || new ethers.Contract(nftAddress, ERC721ABI, provider)
    return (
      (await contract.supportsInterface(INTERFACE_ID.ERC721)) ||
      (await contract.supportsInterface(INTERFACE_ID.KAP721))
    )
  } catch (err) {
    console.error(err)
    return false
  }
}

export const isERC1155 = async (nftAddress, nftContract) => {
  try {
    const contract =
      nftContract || new ethers.Contract(nftAddress, ERC721ABI, provider)
    return await contract.supportsInterface(INTERFACE_ID.ERC1155)
  } catch (err) {
    console.error(err)
    return false
  }
}

const getURIData = async (uri, signal) => {
  console.log(uri)

  if (uri.startsWith('data:application/json;base64,')) {
    return JSON.parse(atob(uri.substring(29)))
  }

  const url = ipfsToHttps(uri)
  if (!url.toLowerCase().startsWith('http')) return {}

  try {
    const response = await axios.get(url, {
      signal,
    })
    const data = response.data.data ?? response.data

    return data
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log('Request canceled', uri)
      return {}
    }
    if (err.message !== 'Network Error') {
      console.error(err)
      return {}
    }

    try {
      const response = await axios.get(
        `${corsAnywhere}${encodeURIComponent(url)}`,
        { signal }
      )
      const data = response.data.data ?? response.data

      return data
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled', err.message)
      } else {
        console.error(err)
      }
      return {}
    }
  }
}

const getERC721Data = async (
  walletAddress,
  nftAddress,
  tokenIdList = [],
  signal
) => {
  const contract = new ethers.Contract(nftAddress, ERC721ABI, provider)

  if (!(await isSupportMetadata(nftAddress, contract))) return

  const name = await contract.name()
  const symbol = await contract.symbol()

  const enumerable = await isSupportEnumerable(nftAddress, contract)

  let idList = tokenIdList

  if (walletAddress) {
    const balance = await contract.balanceOf(walletAddress)

    if (!balance || balance.eq(0)) return

    if (enumerable) {
      idList = (
        await Promise.all(
          [...new Array(balance.toNumber()).keys()].map(
            async (index) =>
              await contract.tokenOfOwnerByIndex(walletAddress, index)
          )
        )
      )
        .map((id) => id.toString())
        .sort((a, b) => a - b)
    }
  }

  const tokens = (
    await Promise.all(
      idList.map(async (tokenId) => {
        const owner = await contract.ownerOf(tokenId)
        if (
          walletAddress &&
          owner &&
          getAddress(owner) !== getAddress(walletAddress)
        )
          return

        const tokenURI = await contract.tokenURI(tokenId)
        if (!tokenURI)
          return { id: tokenId.toString(), name: '', description: '' }

        const tokenData = await getURIData(tokenURI, signal)
        Object.keys(tokenData).forEach(
          (key) => (tokenData[key] = ipfsToHttps(tokenData[key]))
        )

        return {
          name: '',
          description: '',
          image: '',
          ...tokenData,
          id: tokenId.toString(),
          owner,
        }
      }) || []
    )
  )
    .filter(Boolean)
    .sort((a, b) => a.id - b.id)

  return {
    type: TOKEN_TYPE.ERC721,
    address: getAddress(nftAddress),
    name,
    symbol,
    tokens,
    enumerable,
  }
}

const getERC1155Data = async (
  walletAddress,
  nftAddress,
  tokenIdList = [],
  signal
) => {
  const contract = new ethers.Contract(nftAddress, ERC1155ABI, provider)

  const tokens = (
    await Promise.all(
      tokenIdList.map(async (tokenId) => {
        let balance = BigNumber.from(0)

        if (walletAddress) {
          balance = await contract.balanceOf(walletAddress, tokenId)
          if (!balance || balance.eq(0)) return
        }

        if (
          !(await contract.supportsInterface(INTERFACE_ID.ERC1155Metadata_URI))
        ) {
          return { id: tokenId.toString(), balance, decimals: 0 }
        }

        const tokenURI = await contract.uri(tokenId)
        if (!tokenURI)
          return {
            id: tokenId.toString(),
            name: '',
            description: '',
            balance,
            decimals: 0,
          }

        const replaceTokenID = replaceID(tokenId)

        const tokenData = await getURIData(replaceTokenID(tokenURI), signal)
        Object.keys(tokenData).forEach(
          (key) =>
            (tokenData[key] = replaceTokenID(ipfsToHttps(tokenData[key])))
        )

        return {
          name: '',
          description: '',
          image: '',
          decimals: 0,
          ...tokenData,
          id: tokenId.toString(),
          balance,
        }
      })
    )
  )
    .filter(Boolean)
    .sort((a, b) => a.id - b.id)

  return {
    type: TOKEN_TYPE.ERC1155,
    address: getAddress(nftAddress),
    tokens,
  }
}

const getRyoiiNFTData = async (
  walletAddress,
  nftAddress,
  tokenIdList = [],
  signal
) => {
  const contract = new ethers.Contract(nftAddress, ERC721ABI, provider)

  const name = await contract.name()
  const symbol = await contract.symbol()

  const tokens = (
    await Promise.all(
      tokenIdList.map(async (tokenId) => {
        const owner = await contract.ownerOf(tokenId)
        if (
          walletAddress &&
          owner &&
          getAddress(owner) !== getAddress(walletAddress)
        )
          return

        const tokenURI = await contract.tokenURI(tokenId)
        if (!tokenURI)
          return { id: tokenId.toString(), name: '', description: '' }

        const tokenData = await getURIData(tokenURI, signal)
        Object.keys(tokenData).forEach(
          (key) => (tokenData[key] = ipfsToHttps(tokenData[key]))
        )

        tokenData.name = tokenData.restaurant
        tokenData.properties = {
          Restaurant: tokenData.restaurant,
          Title: tokenData.title,
          'Start Date': tokenData.startDate,
          'Expire Date': tokenData.expireDate,
        }

        return {
          name: '',
          description: '',
          image: '',
          ...tokenData,
          id: tokenId.toString(),
          owner,
        }
      }) || []
    )
  )
    .filter(Boolean)
    .sort((a, b) => a.id - b.id)

  return {
    type: TOKEN_TYPE.ERC721,
    address: getAddress(nftAddress),
    name,
    symbol,
    tokens,
  }
}

export const getNFTData = async (
  walletAddress,
  nftAddress,
  tokenIdList = [],
  signal
) => {
  try {
    const contract = new ethers.Contract(nftAddress, ERC721ABI, provider)

    const args = [walletAddress, nftAddress, tokenIdList, signal]

    if (getAddress(ryoiiNFTAddress) === getAddress(nftAddress)) {
      const data = await getRyoiiNFTData(...args)
      console.log(data)
      return data
    }

    if (await isERC721(nftAddress, contract)) {
      const data = await getERC721Data(...args)
      console.log(data)
      return data
    }

    if (await isERC1155(nftAddress, contract)) {
      const data = await getERC1155Data(...args)
      console.log(data)
      return data
    }
  } catch (err) {
    console.error(err)
  }
}

export const getPromiseNFTDataList = (
  walletAddress,
  nftList = [],
  tokenIds = {},
  signal
) => {
  return (
    nftList
      ?.map(
        async (nftAddress) =>
          await getNFTData(
            walletAddress,
            nftAddress,
            tokenIds?.[getAddress(nftAddress)] || [],
            signal
          )
      )
      ?.filter(Boolean) || []
  )
}

export const transferNFT = async (
  walletAddress,
  nftAddress,
  tokenId,
  toAddress,
  amount = 1
) => {
  const contract = new ethers.Contract(nftAddress, ERC721ABI, signer)
  if (await isERC721(nftAddress, contract)) {
    const tx = await contract['safeTransferFrom(address,address,uint256)'](
      walletAddress,
      toAddress,
      tokenId
    )
    console.log({ tx })
    const receipt = await tx.wait()
    console.log({ receipt })
    return
  }
  if (await isERC1155(nftAddress, contract)) {
    const contract = new ethers.Contract(nftAddress, ERC1155ABI, signer)
    const tx = await contract.safeTransferFrom(
      walletAddress,
      toAddress,
      tokenId,
      amount,
      '0x'
    )
    console.log({ tx })
    const receipt = await tx.wait()
    console.log({ receipt })
    return
  }
  throw new Error('Support transfer only ERC721 and ERC1155.')
}
