import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.setFetchingPercents: {
      const { fetchingPercents } = action.payload
      return Math.max(state, Math.min(100, fetchingPercents))
    }
    case ACTION_TYPE.clearFetchingPercents: {
      return 0
    }
    default: {
      return state
    }
  }
}

const initialState = 0

const initialize = (initialState = 0) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
