import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.showLoading: {
      return true
    }
    case ACTION_TYPE.hideLoading: {
      return false
    }
    default: {
      return state
    }
  }
}

const initialState = false

const initialize = (initialState = false) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
